import "./TutorialModal.scss";
import { useTranslation } from 'react-i18next';
import { useConfig } from "../../../contexts/useConfig";


import CloseButton from "../../Buttons/Close/CloseButton";
import Modal from "../Modal/Modal";
import { useCallback, useEffect, useRef, useState } from "react";
import { getPropertyOrDefault } from "../../../helpers/helpers";
import ReydarLogo from "../../ReydarLogo/ReydarLogo";

const TutorialModal = (props) => {
   const {t} = useTranslation();
   const [config] = useConfig();
   const modalInnerRef = useRef();
   const videoFrameRef = useRef();
   const topBarRef = useRef();
   const bottomBarRef = useRef();
   const videoAutoplay = getPropertyOrDefault(config?.tutorial?.videoAutoplay, true)
   const closeOnVideoEnded = getPropertyOrDefault(config?.tutorial?.closeOnVideoEnded, true)
   


   const closeVideo = () => {
    props.updateModalState(false)
   }
   
   const getTutorialHeadingLines = useCallback(() => {
      switch (props.viewerType) {
         case "product":
            return t('tutorialProductsHeading').split('\n');
         case "sample":
            return t('tutorialSamplesHeading').split('\n');
         default:
            return []
      }
   }, [props.viewerType, t])

   const getTutorialInfoLines = useCallback(() => {
      switch (props.viewerType) {
         case "product":
            return t('tutorialProductsInfo').split('\n');
         case "sample":
            return t('tutorialSamplesInfo').split('\n');
         default:
            return []
      }
   }, [props.viewerType, t])

   const getTutorialVideoUrl = () => {
      switch (props.viewerType) {
         case "product":
            return "https://cdn.enginecloud.co.uk/files/reydar-products-howto-captions.mp4"
         case "sample":
            return "https://cdn.enginecloud.co.uk/files/reydar-samples-howto-captions.mp4"
         default:
            return ""
   }
}

   useEffect(() => {
      setTutorialHeadingLines(getTutorialHeadingLines())
      setTutorialInfoLines(getTutorialInfoLines())
      }, [t, getTutorialHeadingLines, getTutorialInfoLines])

   const [tutorialHeadingLines, setTutorialHeadingLines ] = useState(getTutorialHeadingLines());
   const [tutorialInfoLines, setTutorialInfoLines] = useState(getTutorialInfoLines());

   const tutorialVideoUrl = getTutorialVideoUrl()


// Function to adjust video size dynamically
const resizeVideo = () => {
   const modalInner = modalInnerRef.current;
   const video = videoFrameRef.current;
   const topBar = topBarRef.current;
   const bottomBar = bottomBarRef.current;
 
   if (!video) {
     console.error("No video");
     return;
   }
 
   // Get viewport dimensions
   const viewportWidth = window.innerWidth;
   const viewportHeight = window.innerHeight;
 
   // Constants
   const remToPixels = (rem) =>
     rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
   const padding = remToPixels(1); // 16px
   const topBarHeight = topBar.clientHeight; // 40px
   const bottomBarHeight = bottomBar.clientHeight; // 40px
   console.log(topBarHeight, bottomBarHeight)
   const gap = remToPixels(1); // 16px
 
   // Max modal dimensions
   const maxModalWidth = 0.95 * viewportWidth;
   const maxModalHeight = 0.95 * viewportHeight;
 
   // Available space for the video
   let availableHeightForVideo =
     maxModalHeight - 2 * padding - topBarHeight -  gap;

   if (bottomBarHeight !== 0) {
      availableHeightForVideo -= gap;
      availableHeightForVideo -= bottomBarHeight;
   }
   const availableWidthForVideo = maxModalWidth - 2 * padding;
 
   // Video size (square)
   const videoSize = Math.min(availableHeightForVideo, availableWidthForVideo);
 
   // Modal dimensions
   const modalWidth = videoSize + 2 * padding;
   const modalHeight =
     videoSize + 2 * padding + topBarHeight + bottomBarHeight + 2 * gap;
 
   // Ensure modal dimensions do not exceed maximum allowed
   const finalModalWidth = Math.min(modalWidth, maxModalWidth);
   const finalModalHeight = Math.min(modalHeight, maxModalHeight);
 
   // Apply modal dimensions
   modalInner.style.width = `${finalModalWidth}px`;
   modalInner.style.height = `${finalModalHeight}px`;
 
   // Apply video dimensions
   video.style.width = `${videoSize}px`;
   video.style.height = `${videoSize}px`;
 
   // Adjust top and bottom bars to match video width
   topBar.style.maxWidth = `${videoSize}px`;
   bottomBar.style.maxWidth = `${videoSize}px`;
 };
 

    // Call the function on mount and window resize
    useEffect(() => {
        resizeVideo();
        window.addEventListener('resize', resizeVideo);
    
        return () => window.removeEventListener('resize', resizeVideo); // Clean up the event listener
      }, []);

      useEffect(() => {
        resizeVideo()
      }, [props.canShow])

   return (
      <Modal canShow={props.canShow} onClickBackground={closeVideo} id="tutorial-modal" className={"tutorial-modal__inner"} modalInnerRef={modalInnerRef}>
         <div className="top-bar" ref={topBarRef}>
            
            <div className="tutorial-modal__title">
                <h2 >
                    {tutorialHeadingLines.map((line, index) => <span key={index}>{line}<br/></span>)}
                </h2>
            </div>
            <CloseButton closeModal={() => props.updateModalState(false)} />
         </div>
         <div className="tutorial-modal__content">
         <video 
            playsInline
            webkit-playsinline="true"
            autoPlay = {videoAutoplay ? true : null}
            className="tutorial-video modal__video-style" 
            onEnded={closeOnVideoEnded ? closeVideo : null}
            ref={videoFrameRef}>
            <source src={tutorialVideoUrl} type="video/mp4"/>
        </video>
         </div>
         <div className="bottom-bar" ref={bottomBarRef}>
            <div className="logo">
               <ReydarLogo />
            </div>
         <div className="tutorial-modal__info join-paragraph">
            {tutorialInfoLines.map((line, index) => <p key={index} className="">{line}&nbsp;</p>)}
         </div>
         </div>
      </Modal>
   );
};

export default TutorialModal;
