import "./ContactModal.scss";
import Modal from "../../Modals/Modal/Modal";
import CloseButton from "../../Buttons/Close/CloseButton";
import { useConfig } from "../../../contexts/useConfig";

import PhoneIcon from "../../../assets/icons/phone.svg";
import MailIcon from "../../../assets/icons/email.svg";
import GlobeIcon from "../../../assets/icons/website.svg";
import DownloadIcon from "../../../assets/icons/download.svg";

export default function ContactModal(props) {
   const [config] = useConfig();

   return (
      <Modal canShow={props.canShow} className="contact-modal" onClickBackground={() => props.updateModalState(false)}>
         <div className="top-bar" >
            <h2 className="contact-modal__title">Contact Us</h2>
            <CloseButton closeModal={() => props.updateModalState(false)} />
         </div>
         {

            <div className="base-elem">
               {(config.contact.contactMail &&
                  <div className="contact-row">
                     <div className="row-head">
                        <div className="img-cont">
                           <img src={MailIcon} alt="Email Icon" />
                        </div>
                        <div className="text-cont">
                           <p>Speak to our product expert.<br /></p>
                        </div>
                     </div>
                     <a href={'mailto:' + (config.contact.contactMail) + (config.contact.mailCustomSubjectLine ? "?subject=" + (config.contact.mailCustomSubjectLine) : "?subject=Sent from Reydar 3D Viewer:")}>{config.contact.contactMail}</a>
                  </div>
               )}
               {(config.contact.contactPhone &&
                  <div className="contact-row">
                     <div className="row-head">
                        <div className="img-cont">
                           <img src={PhoneIcon} alt="Phone Icon" />
                        </div>
                        <div className="text-cont">
                           <p>Questions? Let us help.<br /></p>
                        </div>
                     </div>
                     <a href={'tel:' + (config.contact.contactPhone)}>{config.contact.contactPhone}</a>
                  </div>
               )}
               {(config.contact.contactSiteLink &&
                  <div className="contact-row">
                     <div className="row-head">
                        <div className="img-cont">
                           <img src={GlobeIcon} alt="Globe Icon" />
                        </div>
                        <div className="text-cont">
                           <p>Visit our website.<br /></p>
                        </div>
                     </div>
                     <a target="_blank" rel="noreferrer" href={(config.contact.contactSiteLink)}>{config.contact.contactSiteLink}</a>
                  </div>
               )}
               {(config.contact.secondarySiteLink &&
                  <div className="contact-row">
                     <div className="row-head">
                        <div className="img-cont">
                           <img src={DownloadIcon} alt="Download Icon" />
                        </div>
                        <div className="text-cont">
                           <p>Downloadable Content.<br /></p>
                        </div>
                     </div>
                     <a target="_blank" rel="noreferrer" href={(config.contact.secondarySiteLink)}>{config.contact.secondarySiteLinkHeading}</a>
                  </div>
               )}
            </div>
         }
      </Modal>
   );
}
