import "./QrCodeModal.scss";
import { ReactComponent as QRSurround } from "../../../assets/qr-surround.svg";
import { QRCodeSVG } from "qrcode.react";
import { useShareUrl } from "../../../hooks/useShareUrl";
import { useTranslation } from 'react-i18next';
import { useConfig } from "../../../contexts/useConfig";


import CloseButton from "../../Buttons/Close/CloseButton";
import HandoffIcon from "../../../assets/icons/handoff.png";
import Modal from "../Modal/Modal";
import { useEffect, useState } from "react";
import { getPropertyOrDefault } from "../../../helpers/helpers";

const QrCodeViewer = (props) => {
   const {t} = useTranslation();
   const shareUrl = useShareUrl();
   const [qrHeadingLines, setQrHeadingLines] = useState(t('qrHeading').split('\n'));
   const [config] = useConfig();
   const url = getPropertyOrDefault(config["customQrCodeUrl"], shareUrl + "&handoff")

   
useEffect(() => {
      setQrHeadingLines(t('qrHeading').split('\n'))
   }, [t])

   return (
      <Modal canShow={props.canShow} onClickBackground={() => props.updateModalState(false)} id="qr-code-modal">
         <div className="top-bar">
            <CloseButton closeModal={() => props.updateModalState(false)} />
         </div>
         <h2 className="qr-code-modal__title">
            {qrHeadingLines.map((line, index) => <span key={index}>{line}<br/></span>)}
         </h2>

         {/* <div className="qr-code-modal__qr">
            <div className="qr-code-modal__qr__bg"/>
            <QRCodeSVG value={url} size={102} className="qr-code" />
            <QRSurround className="qr-surround" />
         </div> */}
<div className="qr-code-section">
<div className="container">
   <QRCodeSVG value={url} size={102} className="qr-code" />
   <QRSurround className="surround" />
   <div className="bg"></div>
   </div>
</div>



         <footer className="qr-code-modal__footer">
            <img src={HandoffIcon} alt="handoff icon" aria-hidden="true" />
            <strong>{t('qrSecHeading')}</strong>
            <p>{t('qrSecText')}</p>
         </footer>
      </Modal>
   );
};

export default QrCodeViewer;
