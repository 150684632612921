import "./ActionBar.scss";

import { useConfig } from "../../contexts/useConfig";
import FullscreenButton from "../Buttons/Fullscreen/FullscreenButton";
import InformationButton from "../Buttons/Information/InformationButton";
import SupportInformationButton from "../Buttons/SupportInformation/SupportInformationButton";
import ContactButton from "../Buttons/Contact/ContactButton";
import ShareButton from "../Buttons/Share/ShareButton";
import RegionButton from "../Buttons/Region/RegionButton";
import ActionButtonContainer from "../ActionButtonContainer/ActionButtonContainer";
import DimensionButton from "../Buttons/Dimensions/DimensionsButton";
import DisclaimerAccordionModal from "../Modals/Disclaimer/DisclaimerAccordion/DisclaimerAccordionModal";

const ActionBar = (props) => {
   const [config] = useConfig();

   const fullscreenAvailable = document.fullscreenEnabled || document.msFullScreenEnable || document.webkitFullscreenEnabled;

   if (!props.isVisible) {
      return null
   }
   const disclaimerAlwaysVisible = (config.actions.disclaimer && config.disclaimerInformation.disclaimerMode === "alwaysVisible");

   return (
      <>
         {disclaimerAlwaysVisible &&
            <DisclaimerAccordionModal deviceType={"mobile"} />
         }
         <div className={(disclaimerAlwaysVisible ? "action-bar__container disc-visible" : "action-bar__container")}>
            <div className="action-bar">
               <div className="action-bar__left">
                  <InformationButton />
                  <ContactButton />
                  <SupportInformationButton />
               </div>

               <ActionButtonContainer deviceType={"desktop"} />
               <div className="action-bar__right">

                  {<RegionButton />}
                  <ShareButton />
                  <FullscreenButton />
                  {!fullscreenAvailable && (
                     <DimensionButton />
                  )}
               </div>
            </div>
            <div className="action-bar__lower">
               <div className="action-bar__right">
                  {fullscreenAvailable && (
                     <DimensionButton />
                  )}
               </div>
            </div>
         </div>
      </>
   );
};

export default ActionBar;
