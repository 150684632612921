import { useState } from "react";
import { useConfig } from "../../../contexts/useConfig";

import ReactGA from "react-ga4";
import ActionButtonStandard from "../Action/ActionButtonStandard";
import InformationIcon from "../../../assets/icons/information.png";
import InformationModal from "../../Modals/Information/InformationModal";
import { useViewerMode } from "../../../contexts/useViewerMode";
import { getPropertyOrDefault } from "../../../helpers/helpers";
import TutorialModal from "../../Modals/Tutorial/TutorialModal";

export default function InformationButton() {
   const [showModal, setShowModal] = useState(false);
   const [config] = useConfig();

   const [viewerMode, setViewerMode] = useViewerMode();

   const viewerType = getPropertyOrDefault(config["viewerType"], "product");

   // const {
   //    setVideoModalVisible,
   //    setVideoModalUrl,
   //    setVideoModalTitle,
   //    setVideoTextContent,
   //    setVideoModalSource,
   //    setVideoAutoplay,
   //    setCloseOnVideoEnded
   // } = useVideoModal();

   function handleClick() {
      if (viewerMode === "select") {
         setViewerMode("view")
      }

      // if (viewerType === "sample") {
      //    setVideoModalVisible(true);
      //    setVideoModalUrl(sampleViewerVideoUrl);
      //    setVideoModalSource("fileUrl");
      //    setVideoModalTitle("How to use the Reydar Samples Viewer");
      //    setVideoTextContent("Learn about viewing samples in AR withReydar.");
      //    setVideoAutoplay(true);
      //    setCloseOnVideoEnded(false)
      // }

      setShowModal(true);
      ReactGA.event({
         category: "Modal View",
         action: "reybeam_information",
      });
   }

   const displayHelp = () => {
      if (viewerType.toLowerCase() === "sample" || viewerType.toLowerCase() === "samples") {
         return <TutorialModal 
         canShow={showModal}
         updateModalState={setShowModal}
         viewerType={"sample"}
         />
         
      } else if (viewerType.toLowerCase() === "product" || viewerType.toLowerCase() === "products") {
         return <TutorialModal 
         canShow={showModal}
         updateModalState={setShowModal}
         viewerType={"product"}
         />
      } else {
         return <InformationModal canShow={showModal} updateModalState={setShowModal} />
      }

   }


   return (
      config.actions.information && (
         <>
            <ActionButtonStandard
               className="information-button"
               iconImg={InformationIcon}
               onClick={handleClick}
            />
            {displayHelp()}
            </>
      )
   );
}
