import React, { RefObject, useEffect } from "react";
import "./Dimensions.css";
import { useConfig } from "../../../contexts/useConfig";

interface DimensionsProps {
   modelViewerRef: RefObject<any>;
   active: boolean;
}

export const Dimensions = ({
   modelViewerRef,
   active,
}: DimensionsProps) => {
   const [config,] = useConfig()

   const defaultDimensionsConfig = {
      "dimensionsUnit": "cm",
      "decimalPlaces": 2,
      "lineColor": config["brand-color"],
      "labelColor": "#ffffff",
      "borderColor": config["brand-color"],
      "textColor": config["brand-color"]
   }

   const dimensionsConfig = Object.assign({}, defaultDimensionsConfig, config["dimensionsConfig"])

   // --dimensions-line-color: #ff6600;
   // --dimensions-border-color: #ff6600;
   // --dimensions-label-color: #ff6600;
   // --dimensions-text-color: #ff6600;

   const body = document.querySelector("body");
   body?.style.setProperty("--dimensions-line-color", dimensionsConfig.lineColor);
   body?.style.setProperty("--dimensions-label-color", dimensionsConfig.labelColor);
   body?.style.setProperty("--dimensions-border-color", dimensionsConfig.borderColor);
   body?.style.setProperty("--dimensions-text-color", dimensionsConfig.textColor);
   
   useEffect(() => {
      const modelViewer = modelViewerRef.current;

      const drawLine = (
         svgLine: SVGLineElement,
         dotHotspot1: any,
         dotHotspot2: any,
         dimensionHotspot: any
      ) => {
         if (dotHotspot1 && dotHotspot2) {
            svgLine.setAttribute("x1", dotHotspot1.canvasPosition.x);
            svgLine.setAttribute("y1", dotHotspot1.canvasPosition.y);
            svgLine.setAttribute("x2", dotHotspot2.canvasPosition.x);
            svgLine.setAttribute("y2", dotHotspot2.canvasPosition.y);

            // Determine visibility based on dimension hotspot facing camera
            if (dimensionHotspot) {
               const isFacingCamera = dimensionHotspot.facingCamera;
               svgLine.classList.toggle("backface", !isFacingCamera);
            }
         }
      };

      const renderSVG = () => {
         const dimLines = modelViewer.querySelectorAll("line");
         const lines = [
            ["hotspot-dot+X-Y+Z", "hotspot-dot+X-Y-Z", "hotspot-dim+X-Y"],
            ["hotspot-dot+X-Y-Z", "hotspot-dot+X+Y-Z", "hotspot-dim+X-Z"],
            ["hotspot-dot+X+Y-Z", "hotspot-dot-X+Y-Z", "hotspot-dim+Y-Z"],
            ["hotspot-dot-X+Y-Z", "hotspot-dot-X-Y-Z", "hotspot-dim-X-Z"],
            ["hotspot-dot-X-Y-Z", "hotspot-dot-X-Y+Z", "hotspot-dim-X-Y"],
         ];

         lines.forEach((line, index) => {
            drawLine(
               dimLines[index],
               modelViewer.queryHotspot(line[0]),
               modelViewer.queryHotspot(line[1]),
               modelViewer.queryHotspot(line[2])
            );
         });
      };

      const updateHotspots = () => {
         const center = modelViewer.getBoundingBoxCenter();
         const size = modelViewer.getDimensions();
         const x2 = size.x / 2;
         const y2 = size.y / 2;
         const z2 = size.z / 2;

         const positions = {
            "hotspot-dot+X-Y+Z": `${center.x + x2} ${center.y - y2} ${center.z + z2}`,
            "hotspot-dim+X-Y": `${center.x + x2 * 1.2} ${center.y - y2 * 1.1} ${center.z}`,
            "hotspot-dot+X-Y-Z": `${center.x + x2} ${center.y - y2} ${center.z - z2}`,
            "hotspot-dim+X-Z": `${center.x + x2 * 1.2} ${center.y} ${center.z - z2 * 1.2}`,
            "hotspot-dot+X+Y-Z": `${center.x + x2} ${center.y + y2} ${center.z - z2}`,
            "hotspot-dim+Y-Z": `${center.x} ${center.y + y2 * 1.1} ${center.z - z2 * 1.1}`,
            "hotspot-dot-X+Y-Z": `${center.x - x2} ${center.y + y2} ${center.z - z2}`,
            "hotspot-dim-X-Z": `${center.x - x2 * 1.2} ${center.y} ${center.z - z2 * 1.2}`,
            "hotspot-dot-X-Y-Z": `${center.x - x2} ${center.y - y2} ${center.z - z2}`,
            "hotspot-dim-X-Y": `${center.x - x2 * 1.2} ${center.y - y2 * 1.1} ${center.z}`,
            "hotspot-dot-X-Y+Z": `${center.x - x2} ${center.y - y2} ${center.z + z2}`,
         };

         Object.keys(positions).forEach((key) => {
            modelViewer.updateHotspot({ name: key, position: positions[key] });
         });

         const midpoints = [
            {
               name: "hotspot-dim+X-Y",
               value: size.z,
               position: `${center.x + x2 * 1.2} ${center.y - y2 * 1.1} ${center.z}`,
            },
            {
               name: "hotspot-dim+X-Z",
               value: size.y,
               position: `${center.x + x2 * 1.2} ${center.y} ${center.z - z2 * 1.2}`,
            },
            {
               name: "hotspot-dim+Y-Z",
               value: size.x,
               position: `${center.x} ${center.y + y2 * 1.1} ${center.z - z2 * 1.1}`,
            },
            {
               name: "hotspot-dim-X-Z",
               value: size.y,
               position: `${center.x - x2 * 1.2} ${center.y} ${center.z - z2 * 1.2}`,
            },
            {
               name: "hotspot-dim-X-Y",
               value: size.z,
               position: `${center.x - x2 * 1.2} ${center.y - y2 * 1.1} ${center.z}`,
            },
         ];

         midpoints.forEach((dim) => {
            const text = formatDimension(dim.value, dimensionsConfig.dimensionsUnit, dimensionsConfig.decimalPlaces);
            modelViewer.updateHotspot({ name: dim.name, position: dim.position });
            modelViewer.querySelector(`button[slot="${dim.name}"]`).textContent = text;
         });

         renderSVG();
         modelViewer.addEventListener("camera-change", () => {
            renderSVG();
         });
      };

      modelViewer.addEventListener("load", () => {
         updateHotspots();
      });

      return () => {
         modelViewer.removeEventListener("load", updateHotspots);
         modelViewer.removeEventListener("camera-change", renderSVG);
      };
   }, [modelViewerRef, dimensionsConfig, active]);

   useEffect(() => {
      const modelViewer = modelViewerRef.current;
      const dimElements = [
         ...modelViewer.querySelectorAll(".dim"),
         ...modelViewer.querySelectorAll(".dot"),
         modelViewer.querySelector("#dimLines"),
      ];

      dimElements.forEach((element) => {
         if (active) {
            element.classList.remove("hide-dimensions");
         } else {
            element.classList.add("hide-dimensions");
         }
      });
   }, [modelViewerRef, active]);

   return (
      <>
        <button slot="hotspot-dot+X-Y+Z" className="dot" data-position="1 -1 1" data-normal="1 0 0"></button>
        <button slot="hotspot-dim+X-Y" className="dim" data-position="1 -1 0" data-normal="1 0 0"></button>
        <button slot="hotspot-dot+X-Y-Z" className="dot" data-position="1 -1 -1" data-normal="1 0 0"></button>
        <button slot="hotspot-dim+X-Z" className="dim" data-position="1 0 -1" data-normal="1 0 0"></button>
        <button slot="hotspot-dot+X+Y-Z" className="dot" data-position="1 1 -1" data-normal="0 1 0"></button>
        <button slot="hotspot-dim+Y-Z" className="dim" data-position="0 -1 -1" data-normal="0 1 0"></button>
        <button slot="hotspot-dot-X+Y-Z" className="dot" data-position="-1 1 -1" data-normal="0 1 0"></button>
        <button slot="hotspot-dim-X-Z" className="dim" data-position="-1 0 -1" data-normal="-1 0 0"></button>
        <button slot="hotspot-dot-X-Y-Z" className="dot" data-position="-1 -1 -1" data-normal="-1 0 0"></button>
        <button slot="hotspot-dim-X-Y" className="dim" data-position="-1 -1 0" data-normal="-1 0 0"></button>
        <button slot="hotspot-dot-X-Y+Z" className="dot" data-position="-1 -1 1" data-normal="-1 0 0"></button>
        <svg id="dimLines" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" className="dimensionLineContainer">
          <line className="dimensionLine"></line>
          <line className="dimensionLine"></line>
          <line className="dimensionLine"></line>
          <line className="dimensionLine"></line>
          <line className="dimensionLine"></line>
        </svg>
      </>
   );
};

const formatDimension = (valueInMeters: number, unit: string, decimalPlaces: number): string => {
   const mmPerMeter = 1000;
   const cmPerMeter = 100;
   const mmPerInch = 25.4;
   const mmPerFoot = 304.8;
   const mmPerYard = 914.4;

   const valueInMm = valueInMeters * mmPerMeter;

   switch (unit) {
      case "m":
         return `${valueInMeters.toFixed(decimalPlaces)} ${unit}`;
      case "cm":
         return `${(valueInMeters * cmPerMeter).toFixed(decimalPlaces)} ${unit}`;
      case "mm":
         return `${valueInMm.toFixed(decimalPlaces)} ${unit}`;
      case "in":
         return `${(valueInMm / mmPerInch).toFixed(decimalPlaces)} ${unit}`;
      case "ft":
         return `${(valueInMm / mmPerFoot).toFixed(decimalPlaces)} ${unit}`;
      case "yd":
         return `${(valueInMm / mmPerYard).toFixed(decimalPlaces)} ${unit}`;
      case "ft/in": {
         const feet = Math.floor(valueInMm / mmPerFoot);
         const inches = ((valueInMm % mmPerFoot) / mmPerInch).toFixed(decimalPlaces);
         return `${feet} ft ${inches} in`;
      }
      case "yd/ft/in": {
         const yards = Math.floor(valueInMm / mmPerYard);
         const remainderAfterYards = valueInMm % mmPerYard;
         const feet = Math.floor(remainderAfterYards / mmPerFoot);
         const inches = ((remainderAfterYards % mmPerFoot) / mmPerInch).toFixed(decimalPlaces);
         return `${yards} yd ${feet} ft ${inches} in`;
      }
      default:
         throw new Error(`Unsupported unit: ${unit}`);
   }
};

