import { useConfig } from "../../contexts/useConfig";
import ReydarLogo from "../ReydarLogo/ReydarLogo";
import "./Watermark.scss";

export default function Watermark() {
   const [config] = useConfig();

   return (
      config["show-watermark"] && (
         <div className="watermark">
            <ReydarLogo />
         </div>
      )
   );
}
